import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WindowService } from 'src/coreServices/window.service';

@Component({
  selector: 'geekeno-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
  standalone: true,
})
export class RedirectComponent {
  url: string = '';

  constructor(route: ActivatedRoute, windowService: WindowService) {
    route.data.subscribe((data) => {
      this.url = data['url'];
      windowService.goTo(this.url);
    });
  }
}
