import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root',
})
/**
 * This is a wrapper around the window which ensures that the window can easily
 * be mocked in tests, as well as no operations are performed on the server
 * where there is no global window object.
 *
 * Note: Only some methods and properties are handled explicitly as doing it
 * dynamically is not possible without ruining typing.
 */
export class WindowService {
  constructor(private utilService: UtilsService) {}

  scroll(options: ScrollToOptions): void {
    if (this.utilService.isPlatformBrowser) {
      window.scroll(options);
    }
  }

  reload(): void {
    if (this.utilService.isPlatformBrowser) {
      window.location.reload();
    }
  }

  goTo(url: string): void {
    if (this.utilService.isPlatformBrowser) {
      window.location.href = url;
    }
  }
}
